<template lang="html">
<v-container fluid>
  <v-row>
    <v-col v-for="(peg,i) in pegawai" :key="i">
      <base-material-card
        class="v-card-profile"
        avatar="https://picsum.photos/id/1027/200"
        max-width="300"
      >
        <v-card-text class="text-center">
          <h6 class="display-1 mb-1 grey--text">
            {{peg.jabatan}}
          </h6>

          <h4 class="display-2 mb-1 orange--text text--darken-2">
            {{peg.nama}}
          </h4>

          <p class="font-weight-light grey--text">
            NIP. {{peg.nip}}{{i}}
          </p>

          <v-btn
            color="primary"
            rounded
            class="mr-0"
            to="/skp/detail-target-kerja"
          >
            Lihat Daftar Kinerja
          </v-btn>
        </v-card-text>
      </base-material-card>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
export default {
  data:function(){
    return{
      pegawai:[
        {jabatan: 'Analisis Kinerja', nama:'Kasep Banget', nip:'111100011110000'},
        {jabatan: 'Analisis Kinerja', nama:'Kasep Hijau', nip:'111100011110000'},
        {jabatan: 'Analisis Kinerja', nama:'Buset Banget', nip:'111100011110000'},
        {jabatan: 'Analisis Kinerja', nama:'Kacep Banget', nip:'111100011110000'},
        {jabatan: 'Analisis Kinerja', nama:'Kadep Bongat', nip:'111100011110000'},
      ]
    }
  }
}
</script>

<style lang="css" scoped>
</style>
